
<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import BudgetsDevolutionsStore from "@/modules/budgets-devolutions/budgets-devolutions-store";
import BudgetsDevolutionsPaginate from "@/components/paginate.vue";
import Swal from "sweetalert2";
import BudgetsDevolutionsDelete from "@/modules/budgets-devolutions/components/budgets-devolutions-delete.vue";
import BudgetsDevolutionsRemove from "@/modules/budgets-devolutions/components/budgets-devolutions-remove.vue";
import BudgetsDevolutionsShow from "@/modules/budgets-devolutions/components/budgets-devolutions-show.vue";

export default {
  name: "BudgetsDevolutions",
  data() {
    return {
      title: "Devoluções de pedidos",
      single: "Devolução de pedido",
      items: [
        {text: "Home", to: {name: "home"}},
        {text: "Devoluções de pedidos", active: true},
      ],
      table: [
        {label: "ID", key: "id"},
        {label: "Nome", key: "name"},
        {label: "Identificador", key: "iduuid"},
        {label: "Criação", key: "created_at"},
        {label: "Atualização", key: "updated_at"},
        {label: "...", key: "btn"},
      ],
      list: [],
      showNewForm: false,
      loadingForm: false,
      paginate: {
        total_pages: 1,
        page: 1,
        perPage: 10,
        total: 0,
      },
      loadingPage: false,
      buscar: "",
      form: {
        id: null,
        id_budgets: null,
        id_budgets_status: null,
        id_budgets_steps: null,
        tracking_code: null,
        value: null,
      },
      optionsBudgets: [],
      optionsBudgetsStatus: [],
      optionsBudgetsSteps: [],
    }
  },
  components: {BudgetsDevolutionsShow, BudgetsDevolutionsRemove, BudgetsDevolutionsDelete, BudgetsDevolutionsPaginate, Layout, PageHeader},
  mounted() {
    this.getPaginate(this.paginate.page);
  },
  watch: {
    'form.name': function (newValue) {
      this.form.identifier = this.$slug_format(newValue);
    }
  }, methods: {
    getPaginate(e = 1) {
      this.paginate.page = e;
      this.loadingPage = true;
      BudgetsDevolutionsStore.paginate({
        page: this.paginate.page,
        limit: this.paginate.perPage,
        search: this.buscar,
      }).then((response) => {
        const {data, limit, page, total, total_pages} = response.data;
        this.list = data;
        this.paginate.total = total;
        this.paginate.perPage = limit;
        this.paginate.page = page;
        this.paginate.total_pages = total_pages;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    cleanForm() {
      this.form = {
        id: null,
        id_budgets: null,
        id_budgets_status: null,
        id_budgets_steps: null,
        tracking_code: null,
        value: null,
      }
    },
    edit(item) {
      this.form = JSON.parse(JSON.stringify(item));
      this.showNewForm = true;
    },
    save(event) {
      event.preventDefault();
      this.loadingForm = true;
      if (this.form.id) {
        BudgetsDevolutionsStore.update(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "Devolução de pedido atualizada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar a devolução de pedido",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      } else {
        BudgetsDevolutionsStore.create(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Criado",
            text: "Devolução de pedido criada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
            text: "Erro ao criar a devolução de pedido",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      }
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="loadingPage">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1>{{ title }}</h1>

              <div class="row mb-4 align-items-center">
                <div class="col-md-5">
                  <div class="row align-items-center">
                    <div class="col-md-6">
                      <input
                          v-model="buscar"
                          type="text"
                          placeholder="Pesquisar...."
                          class="form-control"
                          @change="getPaginate(1)"
                      />
                      <span class="d-block d-md-none"></span>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="getPaginate(1)" type="button" class="btn btn-light btn-block">
                        <i class="fa fa-search"></i> Buscar
                      </button>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="buscar=null; getPaginate(1) " class="btn btn-light btn-block">
                        <i class="fa fa-broom"></i> Limpar
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-7 mb-2 text-right align-items-center">
                  <span class="d-block d-md-none p-2"> </span>
                  <div>
                    <button class="btn btn-primary" @click="showNewForm = true">
                      <i class="fa fa-plus"></i>
                      <span v-if="form.id">Editando</span>
                      <span v-if="!form.id">Novo</span>
                    </button>
                  </div>
                  <div v-show="showNewForm">
                    <div class="box-form text-left">
                      <div id="form-novo-edit">
                        <i @click="showNewForm = false; cleanForm();" class="fa float-right fa-2x fa-eye-slash"></i>
                        <h1 class="mt-5 mb-5" v-if="!form.id">Nova {{ single }}</h1>
                        <h1 class="mt-5 mb-5" v-if="form.id">Editando {{ single }}</h1>
                        <div>
                          <b-overlay :show="loadingForm">
                            <b-form @submit="save" class="was-validated">






                              <b-form-group label="Devolução" label-for="input-0" description="Selecione a devolução">
                                <b-form-select id="input-0" v-model="form.id_budgets_status" :options="optionsBudgetsStatus" required></b-form-select>
                              </b-form-group>
                              <b-form-group label="Livro" label-for="input-0" description="Selecione o livro">
                                <b-form-select id="input-0" v-model="form.id_budgets_steps" :options="optionsBudgetsSteps" required></b-form-select>
                              </b-form-group>
                              <b-form-group label="Pedido" label-for="input-0" description="Selecione o pedido">
                                <b-form-select id="input-0" v-model="form.id_budgets" :options="optionsBudgets" required></b-form-select>
                              </b-form-group>


                              <b-form-group label="Código rastreio" label-for="input-1" description="rastreio da entrega">
                                <b-form-input id="input-1" v-model="form.tracking_code" required></b-form-input>
                              </b-form-group>
                              <b-form-group label="Valor devolvido" label-for="input-2" description="Em reais">
                                <b-form-input id="input-2" v-model="form.value" required></b-form-input>
                              </b-form-group>
                              <b-button type="submit" variant="primary">
                                <span v-if="form.id">Salvar alterações</span>
                                <span v-if="!form.id">Cadastrar novo</span>
                              </b-button>
                            </b-form>
                          </b-overlay>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <budgets-devolutions-paginate :paginate="paginate" @change-page="getPaginate"></budgets-devolutions-paginate>
               <b-table striped hover :items="list" :fields="table" class="  table-responsive-sm table-responsive-md">
                <template v-slot:cell(updated_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(created_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(btn)="data">
                  <button @click="edit(data.item)"  class="btn pl-4 pr-4 btn-sm btn-info" ><i class="fa fa-edit"></i> Editar </button>
                  <budgets-devolutions-delete :value="data.item" @change="getPaginate(1)"></budgets-devolutions-delete>
                  <budgets-devolutions-remove :value="data.item" @change="getPaginate(1)"></budgets-devolutions-remove>
                  <budgets-devolutions-show :value="data.item"></budgets-devolutions-show>
                </template>
              </b-table>
              <budgets-devolutions-paginate :paginate="paginate" @change-page="getPaginate"></budgets-devolutions-paginate>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style scoped>

</style>

